<template>
  <div class="OnlineCourse">
    <el-table
      :data="data"
      :max-height="500"
      :height="500"
      ref="onlineCourseTable"
      stripe
      v-loading="loading"
    >
      <el-table-column label="序号" :width="60" type="index" align="center">
        <!-- <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template> -->
      </el-table-column>
      <el-table-column
        prop="coverUrl"
        label="课程封面"
        align="center"
        min-width="120"
      >
        <template slot-scope="scope">
          <el-avatar
            shape="square"
            :style="{ width: '100px', height: '80px' }"
            :src="scope.row.coverUrl"
          >
            <img class="image-none" src="../../../../assets/logo_top.png" />
          </el-avatar>
        </template>
      </el-table-column>
      <el-table-column
        prop="courseName"
        label="课程名称"
        align="center"
        min-width="140"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="teachers"
        label="教师信息"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="learnProgress"
        label="学习进度"
        align="center"
        min-width="140"
      >
        <template slot-scope="scope">
          <el-progress
            :percentage="stuProgress(scope.row.learnProgress)"
            :stroke-width="20"
            text-inside
          ></el-progress>
        </template>
      </el-table-column>
      <el-table-column
        prop="semester"
        label="学期"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="workOnline"
        label="在线作业"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="examOnline"
        label="在线考试"
        align="center"
      ></el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="90"
        v-if="$sysPerKey('eduCourse:list')"
      >
        <template slot-scope="scope">
          <el-button type="primary" @click="onStudy(scope.row, scope.$index)">
            进入学习
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { COURSE_TYPE } from "@/tools/const";
export default {
  name: "onlineCourse",
  props: {
    data: Array,
    loading: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    // 学习进度
    stuProgress() {
      return (val) => {
        if (val) {
          return Number((val * 100).toFixed(2));
        }
        return val;
      };
    },
  },
  methods: {
    handleCurrentChange(page) {
      if (page) this.current = page;
    },

    onStudy(row, index) {
      if (row) {
        // 菜单切换
        let num;
        if (row.courseType === Number(COURSE_TYPE.C2)) {
          num = COURSE_TYPE.C2;
        } else if (row.courseType === Number(COURSE_TYPE.C1)) {
          num = COURSE_TYPE.C1;
        } else {
          num = COURSE_TYPE.C0;
        }
        this.$store.dispatch("set_stu_menu_switch", num);
        this.$router.push("/student/c/study/nav");
      }

      row && this.$store.dispatch("set_stu_mycourse", row);
    },
  },
};
</script>

<style scoped lang="less">
.OnlineCourse {
  .image-none {
    width: 80px;
    height: 60px;
    margin: 10% auto;
  }
  .image-none-text {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    height: 20px;
    line-height: 1;
  }
}
</style>