<template>
  <div class="Student-MyCourse">
    <div class="stu-module-title">我的课程</div>

    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane name="one">
        <span slot="label">
          在修课程
          <el-badge
            v-if="progressCourseNum > 0"
            :value="progressCourseNum"
            :max="99"
            class="item"
          ></el-badge>
        </span>
        <online-course :loading="loading" :data="progressCourseList" />
      </el-tab-pane>
      <el-tab-pane name="two">
        <span slot="label">
          已修课程
          <el-badge
            v-if="completedCourseNum > 0"
            :value="completedCourseNum"
            :max="99"
            class="item"
          >
          </el-badge>
        </span>
        <end-course :data="completedCourseList" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import OnlineCourse from "./module/OnlineCourse.vue";
import EndCourse from "./module/EndCourse.vue";

import { QueryStudentCourseList } from "@/libs/api/course";
export default {
  name: "my-course",
  components: {
    EndCourse,
    OnlineCourse,
  },
  data() {
    return {
      activeName: "one",
      loading: false,
      progressCourseNum: 0,
      progressCourseList: [],
      completedCourseNum: 0,
      completedCourseList: [
        // {
        //   id: 1,
        //   coverUrl:
        //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   courseName: "概率论与数理统计",
        //   teachers: "靓丽元",
        //   learnProgress: 100,
        //   passFlag: "未通过",
        //   semester: "1",
        //   workOnline: "1",
        //   examOnline: "1",
        // },
        // {
        //   id: 2,
        //   coverUrl: "",
        //   courseName: "毕业大作业",
        //   teachers: "靓丽元",
        //   learnProgress: 0,
        //   passFlag: "未通过",
        //   semester: "1",
        //   workOnline: "1",
        //   examOnline: "1",
        // },
        // {
        //   id: 3,
        //   coverUrl:
        //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   courseName: "毕业论文（设计）",
        //   teachers: "靓丽元",
        //   learnProgress: 30,
        //   passFlag: "未通过",
        //   semester: "1",
        //   workOnline: "1",
        //   examOnline: "1",
        // },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handleTabClick(tab) {

    },
    init() {
      this.loading = true;
      QueryStudentCourseList()
        .then((res) => {
          if (res && res.data) {
            const { data } = res;
            this.progressCourseNum = data.progressCourseNum;
            this.progressCourseList = data.progressCourseList;
            this.completedCourseNum = data.completedCourseNum;
            this.completedCourseList = data.completedCourseList;
          }
          this.loading = false;
        })
        .catch(() => {
          this.$message.error("服务器错误，请稍后重试。");
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.Student-MyCourse {
  background: #fff;
  box-shadow: @shadow;
  padding-bottom: 20px;
  .stu-module-title {
    padding: 20px;
  }
  .item {
    position: absolute;
    top: -10px;
    right: auto;
  }
}
</style>